import styled from 'styled-components';
import { CONTAINER, getLayout, getMediaQuery, Layout, ScreenSize } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Container = styled.div`
  ${CONTAINER}
  height: 100vh;
  background-color: ${({ theme }) => theme.background.primary};
	color: ${({ theme }) => theme.foreground.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  ${getLayout(Layout.GRID)}
`

export const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / span 8;
  flex-direction: column;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-column: 4 / span 6;
  }
`

export const Heading = styled.h1`
  ${getTypography(Type.HEADING_1)}
  text-align: center;
  color: ${({theme}) => theme.foreground.primary};  
`

export const Text = styled.div`
  color: ${({theme}) => theme.foreground.secondary};  
  ${getTypography(Type.BODY_1)}
  text-align: center;
`