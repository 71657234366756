exports.components = {
  "component---src-pages-app-book-index-tsx": () => import("./../../../src/pages/app/book/index.tsx" /* webpackChunkName: "component---src-pages-app-book-index-tsx" */),
  "component---src-pages-app-legal-dato-cms-page-legal-slug-tsx": () => import("./../../../src/pages/app/legal/{datoCmsPageLegal.slug}.tsx" /* webpackChunkName: "component---src-pages-app-legal-dato-cms-page-legal-slug-tsx" */),
  "component---src-pages-app-register-complete-tsx": () => import("./../../../src/pages/app/register/complete.tsx" /* webpackChunkName: "component---src-pages-app-register-complete-tsx" */),
  "component---src-pages-app-register-index-tsx": () => import("./../../../src/pages/app/register/index.tsx" /* webpackChunkName: "component---src-pages-app-register-index-tsx" */),
  "component---src-templates-error-404-tsx": () => import("./../../../src/templates/error404.tsx" /* webpackChunkName: "component---src-templates-error-404-tsx" */),
  "component---src-templates-page-home-tsx": () => import("./../../../src/templates/pageHome.tsx" /* webpackChunkName: "component---src-templates-page-home-tsx" */),
  "component---src-templates-page-legal-tsx": () => import("./../../../src/templates/pageLegal.tsx" /* webpackChunkName: "component---src-templates-page-legal-tsx" */),
  "component---src-templates-page-modular-tsx": () => import("./../../../src/templates/pageModular.tsx" /* webpackChunkName: "component---src-templates-page-modular-tsx" */)
}

