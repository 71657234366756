import { useEffect, useRef, useState } from 'react';

export const useVisibleOnScreen = (ref, {offset = { top: 0, bottom: 0 } }={offset:{top:0,bottom:0}}) => {
  const [entry, updateEntry] = useState({
    ratio: 0,
    activated: false,
    active: false,
    visible: false,
  });

  const observer = useRef(null);
  const activated = useRef(null);

  const rootMargin = `${offset.top ? offset.top : 0}px 0px ${offset.bottom ? offset.bottom : 0}px 0px`;

  useEffect(() => {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      if (observer.current) observer.current.disconnect();


      observer.current = new window.IntersectionObserver(
        ([result]) => {
          const active = activated.current === true || result.isIntersecting;

          const e = {
            visible: result.isIntersecting,
            ratio: result.intersectionRatio,
            active: active,
          };

          activated.current = active;

          updateEntry(e);
        },
        {
          rootMargin,
        }
      );

      const { current: currentObserver } = observer;

      if (ref.current) currentObserver.observe(ref.current);

      return () => currentObserver.disconnect();
    } else {
      updateEntry({
        visible: true,
        active: true,
      });
    }
  }, [ref, rootMargin]);

  return entry;
};
