import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';
import { PageQuery, SitePageContext } from '../../../common/types/graphql';
import { ConfigurationProvider } from '../../../context/configuration';
import { DictionaryProvider } from '../../../context/dictionary';
import { LocaleProvider } from '../../../context/locale';
import { Color, getTheme, Theme } from '../../../theme/color';


import '../../../theme/fonts/stylesheet.css';

import { ErrorMessage } from '../../feedback/errorMessage';
import { Footer } from '../footer';
import { Header } from '../header';
import { Container, GlobalStyle } from './page.styles';

const isClient = typeof window !== "undefined";

const Page = ({ children, pageContext }: PageProps<{}, SitePageContext>) => {
  const data = useStaticQuery<PageQuery>(graphql`
    query Page {
      dictionary:datoCmsDictionary {
        heading404
        text404
        headingError
        labelPayAsYouGo
        labelSubscription
        labelSubscriptionDiscount
      }

      site:datoCmsSite {
        locales
      }

      configurationVehicle:datoCmsConfigurationVehicle {
        items {
          heading
          subheading
        }
        sequence {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            forceBlurhash: true
            imgixParams: { fit: "crop", ar: "4:3" }
          )
          loRes: url(imgixParams: {w: "1200", ar: "1:1", q: 40})
          hiRes: url(imgixParams: {w: "1200", ar: "1:1", q: 60})
          url
        }
      }

      configurationPricing:datoCmsConfigurationPricing {
        items {
          heading
          payAsYouGo
          subscription
          media{
            gatsbyImageData(
              layout: CONSTRAINED,
              width: 500, 
              placeholder: BLURRED, 
              forceBlurhash: false,
            )
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (isClient) {
      if (!!window["Beacon"]) {
        window["Beacon"]("once", "ready", () => {
          const beacon = document.getElementById('beacon-container');
          if (beacon) {
            if (pageContext.layout === "app") {
              beacon.style.visibility = "hidden";
            }
          }
        })
      }
    }
  }, [pageContext.layout])

  const locale = pageContext.locale || "en";
  const rootLocale = pageContext.rootLocale || "en";
  const dictionary = get(data, 'dictionary')
  const site = get(data, 'site');

  const configuration = {
    pricing: get(data, 'configurationPricing'),
    vehicle: get(data, 'configurationVehicle'),
  };

  const locales = site.locales;
  const defaultLocale = site.locales[0];

  const pageType = get(pageContext, "pageType");
  const headerTheme = get(pageContext, "theme");

  let pageTheme = Theme.YELLOW;
  let headerPrimaryColor = Color.DARK_BLUE;
  let headerSecondaryColor = Color.PINK;
  let headerButtonColor = Color.PINK;
  let footerLineColor = Color.PINK;

  if (pageContext.layout === "app") {
    pageTheme = Theme.LIGHT_GREY;
  }
  // else if(pageContext.layout === "book"){
  //   pageTheme = Theme.WHITE;
  // }
  else {
    switch (pageType) {
      case "page_home":
        headerPrimaryColor = Color.DARK_BLUE;
        headerSecondaryColor = Color.PINK;
        headerButtonColor = Color.PINK;
        footerLineColor = Color.PINK;
        pageTheme = Theme.WHITE;
        break;
      case "page_modular":
        switch (headerTheme) {
          case "Light blue":
            headerPrimaryColor = Color.YELLOW;
            headerSecondaryColor = Color.WHITE;
            headerButtonColor = Color.PINK;
            footerLineColor = Color.PURPLE;
            pageTheme = Theme.WHITE;
            break;
          case "Dark blue":
            headerPrimaryColor = Color.YELLOW;
            headerSecondaryColor = Color.WHITE;
            headerButtonColor = Color.PINK;
            footerLineColor = Color.PINK;
            pageTheme = Theme.WHITE;
            break;
          case "Pink":
            headerPrimaryColor = Color.YELLOW;
            headerSecondaryColor = Color.WHITE;
            headerButtonColor = Color.DARK_BLUE;
            footerLineColor = Color.PURPLE;
            pageTheme = Theme.WHITE;
            break;
          case "Yellow":
            headerPrimaryColor = Color.DARK_BLUE;
            headerSecondaryColor = Color.PINK;
            headerButtonColor = Color.PINK;
            footerLineColor = Color.PINK;
            pageTheme = Theme.WHITE;
            break;
        }
        break;
      case "page_legal":
        headerPrimaryColor = Color.YELLOW;
        headerSecondaryColor = Color.WHITE;
        headerButtonColor = Color.PINK;
        footerLineColor = Color.PINK;
        pageTheme = Theme.YELLOW;
        break;
    }
  }

  const ErrorPage = ({ error }: { error: Error }) => (
    <Container>
      <ErrorMessage heading={dictionary.headingError} text={error.message} />
    </Container>
  );

  return (
    <ThemeProvider theme={getTheme(pageTheme)}>
      <LocaleProvider locale={locale} defaultLocale={defaultLocale} rootLocale={rootLocale} locales={locales}>
        <DictionaryProvider dictionary={dictionary}>
          <ConfigurationProvider configuration={configuration}>
            <GlobalStyle />
            <ErrorBoundary FallbackComponent={ErrorPage}>
              {pageContext.layout === "app" || pageContext.layout === "web" ?
                children
                :
                <>
                  <Header primaryColor={headerPrimaryColor} secondaryColor={headerSecondaryColor} buttonColor={headerButtonColor} />
                  <Container>{children}</Container>
                  <Footer lineColor={footerLineColor} />
                </>
              }
            </ErrorBoundary>
          </ConfigurationProvider>
        </DictionaryProvider>
      </LocaleProvider>
    </ThemeProvider>
  );
};

export default Page;