export interface ButtonProps {
  className?: string;
  icon?: ButtonIcon;
  label: string;
  url?: string;
  isInternal: boolean;
  type?: ButtonType;
}

export interface ButtonIconProps {
  icon: ButtonIcon;
  type: ButtonType;
}

export enum ButtonIcon {
  APP_STORE = "App store",
  PLAY_STORE = "Play store",
  INSTAGRAM = "Instagram",
}

export enum ButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}