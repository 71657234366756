import { get } from "lodash";

export const isInternal = (url: string) => {
  // check against regex
  return /^\/(?!\/)/.test(url);
};

export const tidyURL = (url: string) => {
  // remove this domain
  const domain = process.env.URL ?? '';
  return url.replace(domain, '');
};

export const getLocalizedPath = ({rootLocale,locale}:{rootLocale?:string,locale?:string}) => {
  return `${!!locale && rootLocale !== locale ? `/${locale}` : ``}`
}

export const standardiseInternalURL = (url: string) => {
  return url.replace(/\/?(\?|#|$)/, '/$1').toLowerCase();
};

export const getPageHomePath = ({rootLocale,locale,addTrailingSlash}:{rootLocale?:string,locale?:string,addTrailingSlash?:boolean}) => {
  return `${getLocalizedPath({rootLocale,locale})}${addTrailingSlash ? "/" : ""}`;
};

export const getPageModularPath = ({rootLocale,locale,slug}:{rootLocale?:string,locale?:string,slug?:string}) => {
  return `${getLocalizedPath({rootLocale,locale})}/${slug + "/" || ""}`;
};

export const getPageLegalPath = ({rootLocale,locale,slug}:{rootLocale?:string,locale?:string,slug?:string}) => {
  return `${getLocalizedPath({rootLocale,locale})}/legal/${slug + "/" || ""}`;
};

export const getNewsHomePath = ({rootLocale,locale}:{rootLocale?:string,locale?:string}) => {
  return `${getLocalizedPath({rootLocale,locale})}/news/`;
};

export const getNewsCategoryPath = ({rootLocale,locale,slug}:{rootLocale?:string,locale?:string,slug?:string}) => {
  return `${getLocalizedPath({rootLocale,locale})}/news/category/${slug + "/" || ""}`;
};

export const getNewsArticlePath = ({rootLocale,locale,slug}:{rootLocale?:string,locale?:string,slug?:string}) => {
  return `${getLocalizedPath({rootLocale,locale})}/news/article/${slug + "/" || ""}`;
};

export const getAppRegisterPath = ({rootLocale,locale}:{rootLocale?:string,locale?:string}={}) => {
  return `${getLocalizedPath({rootLocale,locale})}/app/register`;
}

export const getAppBookPath = ({rootLocale,locale}:{rootLocale?:string,locale?:string}={}) => {
  return `${getLocalizedPath({rootLocale,locale})}/app/book`;
}

export const getInternalURL = ({pageType,locale,slug,rootLocale,forceTrailingSlash}:{pageType:string,locale?:string,slug?:string,rootLocale?:string,forceTrailingSlash?:boolean}):string => {
  switch(pageType){
    case 'page_home':
      return getPageHomePath({rootLocale,locale,addTrailingSlash:forceTrailingSlash})
    case 'page_modular':
      return getPageModularPath({rootLocale,locale,slug})
    case 'page_legal':
      return getPageLegalPath({rootLocale,locale,slug})
    case 'news_home':
      return getNewsHomePath({rootLocale,locale})
    case 'news_article':
      return getNewsArticlePath({rootLocale,locale,slug})
    case 'news_category':
      return getNewsCategoryPath({rootLocale,locale,slug})
    case 'app_register':
      return getAppRegisterPath({rootLocale,locale})
    case 'app_book':
      return getAppBookPath({rootLocale,locale})
  }

  return '/';
}

export const getInternalURLFromPage = ({model,locale,slug}:{model:{apiKey:string},locale?:string,slug?:string},{rootLocale}:{rootLocale?:string}):string => {
  return getInternalURL({pageType:get(model,'apiKey'),locale,slug,rootLocale})
}

export const getAbsoluteURL = ({url,pageType,locale,slug,rootLocale}:{url:string,pageType:string,locale?:string,slug?:string,rootLocale?:string}) => {
  return `${url}${getInternalURL({pageType,locale,slug,rootLocale})}`
}

export const removeTrailingSlash = (path) => {
  return path.length > 1 ? path.replace(/\/$/, "") : path;
}

export const getEmailURL = (address: string) => {
  return `mailto:${address}`;
};

export const getPhoneURL = (phone: string) => {
  return `tel:${phone.replace(/\s+/g, '')}`;
};

export const getLinkDestinationProps = ({phone,email,url}:{phone?:string,email?:string,url?:string}) => {

  if(phone){
    return {
      href: getPhoneURL(phone)
    }
  }
  else if(email){
    return {
      href: getEmailURL(email)
    }
  }
  else if(url){
    return {
      href: url,
      target: "_blank"
    }
  }


  return {}
}

export const addParamsToUrl = (urlString: string, params: Record<string, string | undefined | null>) => {

  let url = new URL(urlString);
  Object.keys(params).forEach((key) => {
    if(params[key] !== null){
      url.searchParams.append(key, params[key] as string);
    }
  });

  return url.toString();
}