import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Color, getColor } from "../../../theme/color";
import { Font, getFont } from "../../../theme/font";
import { ScreenSize, getBreakpointAsNumber, getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels } from "../../../theme/layout";
import { Type, getTypography } from "../../../theme/typography";
import { Button } from "../../common/button";

export const LogoContainer = styled(GatsbyLink)`
  display: block;
  position: absolute;
  z-index: 10;
  top: ${getUnitAsPixels({ multiplier: 3 })};
  left: ${getMarginAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    left: ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    top: ${getUnitAsPixels({ multiplier: 7 })};
    left: ${getMarginAsPixels(ScreenSize.LARGE)};
    z-index: 12;
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    left: ${getMarginAsPixels(ScreenSize.XLARGE)};
  }
`

export const Logo = styled.svg<{ $primaryColor: Color }>`
  fill: ${({ $primaryColor }) => getColor($primaryColor)};
  height: ${getUnitAsPixels({ multiplier: 5 })};

  ${getMediaQuery(ScreenSize.LARGE)}{
    height: ${getUnitAsPixels({ multiplier: 10 })};
  }
`

export const GetApp = styled.a`
  background-color: ${getColor(Color.WHITE)};
  z-index: 10;
  gap: ${getUnitAsPixels({ multiplier: 0.5 })};
  align-items: center;
  flex-direction: column;
  padding: ${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({ multiplier: 1.5 })} ${getUnitAsPixels({ multiplier: 2 })};
  text-decoration: none;
  border-radius: ${getUnitAsPixels({ multiplier: 3 })};
  top: ${getUnitAsPixels({ multiplier: 5 })};
  right: ${getMarginAsPixels(ScreenSize.LARGE)};
  position: fixed;
  transform: rotate(5deg);
  box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
  display: none;

  ${getMediaQuery(ScreenSize.LARGE)}{
    display: flex;
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    right: ${getMarginAsPixels(ScreenSize.XLARGE)};
  }
`

export const MenuButton = styled.button<{ open: boolean }>`
  position: fixed;
  width: ${getUnitAsPixels({ multiplier: 6 })};
  height: ${getUnitAsPixels({ multiplier: 6 })};
  background-color: ${getColor(Color.WHITE)};
  z-index: 10;
  right: ${getMarginAsPixels(ScreenSize.SMALL)};
  top: ${getUnitAsPixels({ multiplier: 3 })};
  border-radius: ${getUnitAsPixels({ multiplier: 1.5 })};
  transform: rotate(10deg);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
  cursor: pointer;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    right: ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getUnitAsPixels({ multiplier: 1.5 })};

  > span {
    position: relative;
    flex: none;
    height: 2px;
    width: 100%;
    background: ${getColor(Color.DARK_BLUE)};
    transition: all 0.4s ease;
    transform: ${({ open }) => open ? `rotate(135deg)` : `none`};
    
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 2px;
      background: inherit;
      transition: all 0.4s ease;
      transform: ${({ open }) => open ? `rotate(90deg)` : `none`};
    }

    &:before {
      top: ${({ open }) => open ? `0` : `-8px`};
    }
    
    &:after {
      top: ${({ open }) => open ? `0` : `8px`};
      opacity: ${({ open }) => open ? `0` : `1`};
    }
  }
`

export const GetAppQR = styled(GatsbyImage)`

`

export const GetAppLabel = styled.span`
  font-family: ${getFont(Font.OBJECT_SANS)};
  text-decoration: none;
  font-weight: 800;
  font-size: 12px;
  text-align: center;
  color: ${getColor(Color.PINK)};
`

export const Menu = styled.nav<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: ${getColor(Color.DARK_BLUE)};
  visibility: ${({ $isOpen }) => $isOpen ? `visible` : `hidden`};
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: ${getUnitAsPixels({ multiplier: 3 })} ${getMarginAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    padding: ${getUnitAsPixels({ multiplier: 3 })} ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    position: absolute;
    height: ${getUnitAsPixels({ multiplier: 10 })};
    background-color: transparent;
    top: ${getUnitAsPixels({ multiplier: 7 })};
    padding: 0;
    align-items: center;
    justify-content: center;
    visibility: visible;
    flex-direction: row;
  }
`

export const LinkItem = styled.li`
  ${getTypography(Type.NAVIGATION)}
`

export const Link = styled.a`
  text-decoration: none;
  color: ${getColor(Color.YELLOW)};
  transition: color 0.3s;

  &:hover{
    color: ${getColor(Color.WHITE)};
  }

  &.active{
    color: ${getColor(Color.WHITE)};
  }
`

export const LinkList = styled.ul<{ $primaryColor: Color, $secondaryColor: Color }>`
  display: flex;
  flex-direction: column;
  gap: ${getUnitAsPixels({ multiplier: 1 })};

  ${getMediaQuery(ScreenSize.LARGE)}{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${getSpacerAsPixels({ multiplier: 1 })};
  }

  ${Link}{
    ${getMediaQuery(ScreenSize.LARGE)}{
      color: ${({ $primaryColor }) => getColor($primaryColor)};

      &:hover{
        color: ${({ $secondaryColor }) => getColor($secondaryColor)};
      }
      
      &.active{
        color: ${({ $secondaryColor }) => getColor($secondaryColor)};
      }
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: ${getUnitAsPixels({ multiplier: 1 })};
  margin-top: ${getUnitAsPixels({ multiplier: 4 })};

  ${getMediaQuery(ScreenSize.LARGE)}{
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: ${getMarginAsPixels(ScreenSize.LARGE)};
    z-index: 12;
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    right: ${getMarginAsPixels(ScreenSize.XLARGE)};
  }
`

export const Birds = styled.svg`
  position: absolute;
  top: ${getUnitAsPixels({ multiplier: 4 })};
  left: ${getMarginAsPixels(ScreenSize.SMALL)};
  width: ${getUnitAsPixels({ multiplier: 18 })};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    left: ${getMarginAsPixels(ScreenSize.MEDIUM)};
    width: ${getUnitAsPixels({ multiplier: 27 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    display: none;
  }
`

export const CTAButton = styled(Button)`

  @media screen and (max-width: ${getBreakpointAsNumber(ScreenSize.MEDIUM) - 1}px){
    background-color: ${getColor(Color.PINK)};

    &:hover{
       background-color: ${getColor(Color.YELLOW)};
    }
  }
`