import React, {Fragment} from 'react';
import Markdown from "markdown-to-jsx";

export const plainTextToHTML = (text?:string) => {
  if(text){
    return (
      text
      .split('\n\n').map((paragraph:string, paragraphIndex:number) => (
        <p key={paragraphIndex}>
          {paragraph.split('\n').map((line:string, lineIndex:number, array:Array<string>) => (
            <Fragment key={lineIndex}>
              {line}
              {lineIndex < array.length - 1 && <br />}
            </Fragment>
          ))}
        </p>
      ))
    )
  }

  return null;
}

export const plainTextToList = (text?:string) => {
  if(text){
    return (
      <ul>
      {text.split(/(?:\n)+/).map((item:string, index:number) => (
        <li key={index}>
          <Markdown>{item}</Markdown>
        </li>
      ))}
      </ul>
    )
  }

  return null;
}

export const plainTextToSingleLine = (text?:string) => {
  return text ? text.replace(/\n/g, " ") : "";
}

export const stripScriptTags = (string?:string) => {
  return string ? string.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "") : "";
}

export const snakeToCamelCase = (str:string) => str.replace(/_([a-z])/g, (g) => { return g[1].toUpperCase(); });
