import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { get } from "lodash";
import React, { useEffect, useRef } from "react";
import { parseLinks } from "../../../common/content";
import { plainTextToHTML } from "../../../common/string";
import { useLocale, useRootLocale } from "../../../context/locale";
import { Color } from "../../../theme/color";
import { Badge, BadgeIcon } from "../../common/badge";
import { MainContainer, Legal, Curve, Content, Inner, IllustrationContainer, Illustration, LinksPrimary, ItemPrimary, LinkPrimary, LinksSecondary, ItemSecondary, LinkSecondary, Bottom, LinksSocial, ItemSocial, LinkSocial, IconSocial, Smallprint, BackLineContainer, FrontLineContainer, SpaBadge, WalkingBadge, RestaurantBadge, Man, Top } from "./footer.styles";
import { FooterProps } from "./footer.types";
import ManLottie from "../../../animations/man.json";
import { useVisibleOnScreen } from "../../../hooks/useVisibleOnScreen";

const isClient = typeof window !== "undefined";

export const Footer = ({lineColor}:FooterProps) => {
  const containerRef = useRef();
  const {visible} = useVisibleOnScreen(containerRef);
  
  const locale = useLocale();
  const rootLocale = useRootLocale();
  
  const data = useStaticQuery(graphql`
    query Footer {
      footer:datoCmsFooter {
        linksPrimary {
          ...LinkInternal
          ...LinkExternal
        }
        linksSecondary {
          ...LinkInternal
          ...LinkExternal
        }
        linksSocial {
          ...LinkSocial
        }
        legal
      }
    }
  `);

  const footer = get(data, 'footer');
  const linksPrimary = parseLinks(get(footer,'linksPrimary'),{rootLocale});
  const linksSecondary = parseLinks(get(footer,'linksSecondary'),{rootLocale});
  const linksSocial = get(footer,'linksSocial');

  useEffect(() => {
    if(isClient){
      const beacon = document.getElementById('beacon-container');
      if(beacon){
        if(visible){
          beacon.style.visibility = "hidden";
        }
        else{
          beacon.style.visibility = "visible";
        }
      }
    }
  },[visible]);

  return (
    <>
    <IllustrationContainer>
      <Curve />
      <Illustration viewBox="0 0 1728 511" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1426.3 142H1382.3V460H1426.3V142Z" fill="#FF6666"/>
        <path d="M1528.2 124C1528.2 116.2 1525.4 109.6 1519.9 104.1C1514.4 98.6004 1507.8 95.9004 1500.1 95.9004H1308.1C1300.3 95.9004 1293.7 98.7004 1288.2 104.1C1282.7 109.6 1280 116.2 1280 124C1280 131.8 1282.7 138.4 1288.2 143.8C1293.7 149.3 1300.3 152 1308.1 152H1500.1C1507.9 152 1514.5 149.2 1519.9 143.8C1525.5 138.4 1528.2 131.8 1528.2 124Z" fill="#019999"/>
        <path d="M1500.1 56.2C1500.1 48.5 1497.4 41.8 1491.9 36.3C1486.4 30.8 1479.8 28.1 1472 28.1C1464.3 28.1 1457.7 30.9 1452.2 36.3L1432.3 56.3V28.1C1432.3 20.3 1429.5 13.7 1424.1 8.2C1418.6 2.7 1412 0 1404.3 0C1396.6 0 1389.9 2.8 1384.3 8.2C1378.8 13.7 1376.1 20.3 1376.1 28.1V56.2L1356.3 36.4C1350.8 30.9 1344.2 28.1 1336.5 28.2C1328.7 28.1 1322.1 30.9 1316.5 36.4C1311 41.9 1308.3 48.5 1308.3 56.4C1308.3 64.1 1311 70.7 1316.5 76.2L1364.5 124.2L1359.7 129.1H1449L1444.1 124.2L1492.1 76.2C1497.4 70.7 1500.1 64 1500.1 56.2Z" fill="#019999"/>
        <path d="M36.6996 145.2C22.7996 145.2 10.5996 149.1 0.0996094 157V513C10.6996 520.9 22.8996 524.9 36.6996 524.9C53.8996 524.9 68.5996 518.8 80.8996 506.6C93.0996 494.5 99.1996 479.8 99.0996 462.6V207.7C99.0996 190.5 92.9996 175.7 80.8996 163.5C68.6996 151.3 53.9996 145.2 36.6996 145.2Z" fill="#019999"/>
        <path d="M137.6 196.4C124.1 196.4 112.5 201.2 102.9 210.8C93.3 220.4 88.5 232 88.5 245.6V446.1C88.5 459.7 93.3 471.2 102.9 480.8C112.5 490.4 124.1 495.2 137.6 495.2C151.2 495.2 162.7 490.4 172.4 480.8C182 471.2 186.7 459.7 186.7 446.1V245.5C186.7 231.9 181.9 220.4 172.4 210.7C162.8 201.1 151.2 196.4 137.6 196.4Z" fill="#019999"/>
        <path d="M1703.3 150.209C1691.1 162.516 1685 177.223 1685 194.432V449.467C1685 466.677 1691.1 481.384 1703.3 493.591C1712.7 502.996 1723.6 508.799 1736 511V133C1723.6 135.101 1712.7 140.904 1703.3 150.209Z" fill="#019999"/>
        <path d="M1677.3 232.4C1667.7 222.8 1656.1 218 1642.5 218C1629 218 1617.4 222.8 1607.8 232.4C1598.2 242 1593.4 253.6 1593.4 267.2V467.8C1593.4 481.4 1598.2 492.9 1607.8 502.5C1617.4 512.1 1629 516.9 1642.5 516.9C1656.1 516.9 1667.6 512.1 1677.3 502.5C1686.9 492.9 1691.6 481.4 1691.6 467.8V267.1C1691.7 253.5 1686.9 241.9 1677.3 232.4Z" fill="#019999"/>
        <path d="M1468.04 274C1460.59 274 1454.22 276.643 1448.93 281.887C1443.65 287.173 1441 293.543 1441 300.997V319.067C1440.48 318.46 1439.96 317.853 1439.36 317.247C1435.2 313.087 1430.17 311.05 1424.28 311.05C1418.43 311.05 1413.4 313.13 1409.24 317.247C1405.08 321.407 1403 326.433 1403 332.327V392.647H1533V317.377C1533 311.483 1530.92 306.5 1526.8 302.297C1522.64 298.137 1517.62 296.1 1511.72 296.1C1505.87 296.1 1500.85 298.18 1496.69 302.297C1496.08 302.903 1495.56 303.51 1495.04 304.117V300.997C1495.04 293.543 1492.4 287.173 1487.15 281.887C1481.87 276.6 1475.5 274 1468.04 274Z" fill="#019999"/>
        <path d="M256.6 313.6C237.4 294.3 214.1 284.7 186.9 284.7C167.8 284.7 150.7 289.4 135.5 298.8C134.4 297.6 133.3 296.5 132.1 295.3C109.1 272.3 81.4 260.9 48.9 260.9C31.2 260.9 14.9 264.3 0 271.1V513H568.9C570.2 507.3 570.8 501.4 570.8 495.2C570.8 473.9 563.3 455.7 548.2 440.6C533.1 425.5 514.9 418 493.6 418C474.3 418 457.6 424.2 443.4 436.5C439.9 413.7 429.5 393.8 412.3 376.6C390.8 355 364.7 344.2 334.2 344.2C315.1 344.2 297.8 348.4 282.2 356.8C277.9 340.6 269.3 326.2 256.6 313.6Z" fill="#94D483"/>
        <path d="M1540.98 357.737C1525.25 349.333 1507.8 345.132 1488.55 345.132C1457.8 345.132 1431.48 355.936 1409.8 377.545C1392.46 394.752 1381.98 414.66 1378.45 437.469C1364.13 425.164 1347.29 418.962 1327.84 418.962C1306.36 418.962 1288.01 426.465 1272.79 441.571C1257.56 456.577 1250 474.784 1250 496.193C1250 502.395 1250.6 508.298 1251.92 514H1740V267C1722.26 272.502 1706.43 282.206 1692.31 296.212C1691.1 297.412 1689.99 298.513 1688.88 299.713C1673.56 290.309 1656.32 285.608 1637.16 285.608C1609.74 285.608 1586.24 295.211 1566.89 314.519C1553.98 327.124 1545.41 341.53 1540.98 357.737Z" fill="#94D483"/>
        <path d="M1695.19 281.569V281.469C1693.49 278.769 1690.99 277.069 1687.99 276.369C1684.89 275.669 1681.99 276.269 1679.19 277.869L1674.99 280.469L1672.29 276.369C1670.59 273.669 1668.29 271.969 1665.29 271.369C1662.09 270.569 1659.19 271.069 1656.49 272.869C1653.79 274.569 1652.19 276.969 1651.49 279.969C1650.79 283.069 1651.19 285.969 1652.79 288.669L1655.59 292.969L1651.49 295.469C1648.79 297.169 1647.09 299.569 1646.29 302.669C1645.59 305.769 1646.19 308.669 1647.89 311.369H1647.99C1649.59 314.169 1651.99 315.869 1655.09 316.569C1658.19 317.269 1661.09 316.769 1663.79 314.969L1667.99 312.469L1670.39 316.469C1672.09 319.169 1674.49 320.869 1677.69 321.469C1680.79 322.169 1683.69 321.769 1686.29 320.069H1686.39C1689.09 318.369 1690.79 315.869 1691.49 312.769C1692.09 309.669 1691.59 306.769 1689.99 304.169L1687.39 300.169L1691.49 297.469C1694.19 295.769 1695.89 293.469 1696.59 290.369C1697.29 287.169 1696.89 284.269 1695.19 281.569Z" fill="#FF3399"/>
        <path d="M1679.69 291.269C1678.09 289.069 1676.09 287.669 1673.49 287.069C1670.89 286.469 1668.49 286.869 1666.19 288.269C1663.99 289.669 1662.49 291.669 1661.89 294.369C1661.39 296.969 1661.79 299.369 1663.19 301.569C1664.59 303.869 1666.59 305.269 1669.19 305.769C1671.79 306.469 1674.29 306.069 1676.49 304.669C1678.79 303.269 1680.19 301.269 1680.69 298.569C1681.49 295.969 1681.09 293.469 1679.69 291.269Z" fill="#F5DF4D"/>
        <path d="M985.5 356.8C981.1 340.7 972.6 326.3 959.9 313.6C940.7 294.3 917.4 284.7 890.2 284.7C871.1 284.7 854 289.4 838.8 298.8C837.7 297.6 836.6 296.5 835.4 295.3C812.4 272.3 784.7 260.9 752.2 260.9C719.7 260.9 692 272.4 669 295.3C646 318.3 634.5 346 634.5 378.5C634.5 380.3 634.5 382.2 634.6 384C615.8 387.6 599.3 396.6 584.9 411C565.6 430.3 556 453.5 556 480.8C556 492.2 557.7 502.9 561.1 512.9H1272.3C1273.6 507.2 1274.2 501.3 1274.2 495.1C1274.2 473.8 1266.7 455.6 1251.5 440.5C1236.4 425.4 1218.2 417.9 1196.9 417.9C1177.6 417.9 1160.9 424.1 1146.7 436.4C1143.2 413.6 1132.8 393.7 1115.6 376.5C1094.1 354.9 1068 344.1 1037.5 344.1C1018.3 344.1 1001 348.3 985.5 356.8Z" fill="#94D483"/>
        <path d="M110.3 256.2C107.2 255.5 104.3 256 101.6 257.7L97.3998 260.3L94.6998 256.2C92.9998 253.5 90.6998 251.8 87.6998 251.1C84.4998 250.4 81.5998 250.9 78.8998 252.6C76.1998 254.3 74.5998 256.7 73.8998 259.7C73.0998 262.8 73.5998 265.7 75.1998 268.5L77.9998 272.8L73.7998 275.3C71.0998 277 69.3998 279.4 68.6998 282.5C67.9998 285.6 68.4998 288.5 70.1998 291.1H70.3998C71.9998 293.8 74.2998 295.6 77.4998 296.3C80.5998 297 83.4998 296.5 86.1998 294.8L90.3998 292.2L92.8998 296.2C94.5998 298.9 96.9998 300.6 100.2 301.2C103.3 302 106.2 301.5 108.8 299.8C111.5 298.1 113.2 295.7 114 292.5C114.6 289.4 114.1 286.6 112.5 283.9L109.8 279.9L114 277.1C116.7 275.4 118.4 273 119.1 270C119.9 266.9 119.4 264 117.7 261.2V261.1C115.9 258.6 113.5 256.9 110.3 256.2Z" fill="#FF3399"/>
        <path d="M99.0001 284.5C101.3 283.1 102.7 281.1 103.2 278.4C103.8 275.8 103.5 273.4 102.1 271.1C100.6 268.9 98.5001 267.5 95.9001 266.9C93.4001 266.3 91.0001 266.7 88.7001 268.1C86.5001 269.4 85.0001 271.4 84.4001 274.1C83.8001 276.7 84.3001 279.1 85.7001 281.3C87.1001 283.6 89.1001 285 91.7001 285.5C94.4001 286.3 96.8001 285.9 99.0001 284.5Z" fill="#F5DF4D"/>
        <path d="M87.4998 327.7C88.2998 324.6 87.7998 321.7 86.0998 318.9C84.3998 316.2 81.9998 314.5 78.7998 313.8C75.6998 313.1 72.7998 313.6 70.0998 315.3L65.8998 317.9L63.1998 313.8C61.4998 311.1 59.1998 309.4 56.1998 308.7C52.9998 308 50.0998 308.5 47.3998 310.3C44.6998 312 43.0998 314.4 42.3998 317.4C41.5998 320.5 42.0998 323.4 43.6998 326.2L46.4998 330.5L42.2998 333C39.5998 334.7 37.8998 337.1 37.1998 340.2C36.4998 343.3 36.9998 346.2 38.6998 348.9H38.8998C40.4998 351.6 42.7998 353.4 45.8998 354.1C48.9998 354.8 51.8998 354.3 54.4998 352.6L58.6998 350L61.1998 354C62.8998 356.7 65.2998 358.4 68.4998 359C71.5998 359.8 74.4998 359.3 77.0998 357.6H77.1998C79.8998 355.9 81.5998 353.5 82.3998 350.3C82.9998 347.2 82.4998 344.4 80.8998 341.7L78.1998 337.7L82.3998 334.9C85.1998 333.1 86.8998 330.8 87.4998 327.7Z" fill="#FF3399"/>
        <path d="M64.5001 324.4C62.0001 323.8 59.6001 324.2 57.3001 325.6C55.1001 326.9 53.6001 328.9 52.9001 331.6C52.3001 334.2 52.8001 336.6 54.2001 338.8C55.6001 341.1 57.6001 342.5 60.2001 343C62.9001 343.7 65.3001 343.3 67.5001 341.9C69.8001 340.5 71.2001 338.5 71.7001 335.8C72.3001 333.2 72.0001 330.8 70.6001 328.5C69.2001 326.4 67.1001 325 64.5001 324.4Z" fill="#F5DF4D"/>
      </Illustration>
      <BackLineContainer>
        {lineColor === Color.PURPLE ?
          <StaticImage src="../../../images/lines/footer-line-purple-back.png" formats={["auto"]} placeholder="none" layout="fullWidth" alt="" />
        :
          <StaticImage src="../../../images/lines/footer-line-pink-back.png" formats={["auto"]} placeholder="none" layout="fullWidth" alt="" />
        }
      </BackLineContainer>
      <SpaBadge icon={BadgeIcon.CHARGE} />
      <WalkingBadge icon={BadgeIcon.WALKING} />
      <RestaurantBadge icon={BadgeIcon.RESTAURANT} />
    </IllustrationContainer>

    <MainContainer ref={containerRef}>
      <FrontLineContainer>
        {lineColor === Color.PURPLE ?
          <StaticImage src="../../../images/lines/footer-line-purple-front.png" formats={["auto"]} placeholder="none" layout="fullWidth" alt="" />
        :
          <StaticImage src="../../../images/lines/footer-line-pink-front.png" formats={["auto"]} placeholder="none" layout="fullWidth" alt="" />
        }
      </FrontLineContainer>
      <Curve />
      <Content>
        <Inner>
          <Top>
            <LinksPrimary>
              {linksPrimary.map((link,index) => <ItemPrimary key={index}>{link.isInternal ? <LinkPrimary as={GatsbyLink} to={link.url} activeClassName="active">{link.label}</LinkPrimary> : <LinkPrimary href={link.url} target="_blank">{link.label}</LinkPrimary>}</ItemPrimary>)}
            </LinksPrimary>
            <Man animationData={ManLottie} autoplay={true} loop={true} />
          </Top>
          <Bottom>
            <LinksSocial>
              {linksSocial.map((link,index) => <ItemSocial key={index}><LinkSocial href={link.url} target="_blank"><IconSocial src={link.icon.url} /></LinkSocial></ItemSocial>)}
            </LinksSocial>
            <Legal>
              <LinksSecondary>
                {linksSecondary.map((link,index) => <ItemSecondary key={index}>{link.isInternal ? <LinkSecondary as={GatsbyLink} to={link.url} activeClassName="active">{link.label}</LinkSecondary> : <LinkSecondary href={link.url} target="_blank">{link.label}</LinkSecondary>}</ItemSecondary>)}
              </LinksSecondary>
              <Smallprint>{plainTextToHTML(footer.legal)}</Smallprint>
            </Legal>
          </Bottom>
        </Inner>
      </Content>
    </MainContainer>
    </>
  );
}