import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import lottie from 'lottie-web/build/player/lottie_light.min.js';
import React from "react";
import { Container } from "./lottieAnimation.styles";
import { LottieAnimationProps } from "./lottieAnimation.types";
import { useVisibleOnScreen } from "../../../hooks/useVisibleOnScreen";

export const LottieAnimation = forwardRef(({className,animationData,loop=false,autoplay=false,horizontalAlign,verticalAlign,fit}:LottieAnimationProps, ref) => {

  const containerRef = useRef();
  const animationRef = useRef();

  const {visible} = useVisibleOnScreen(containerRef);

  useImperativeHandle(ref, () => ({
    play: () => {
      animationRef.current.play()
    },
    pause: () => {
      animationRef.current.pause()
    }
  }));

  let animation;
  
  useEffect(() => {
    animationRef.current = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: loop,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: `x${horizontalAlign === "left" ? `Min` : (horizontalAlign === "right" ? `Max` : `Mid`)}Y${verticalAlign === "top" ? `Min` : (verticalAlign === "bottom" ? `Max` : `Mid`)} ${fit === "cover" ? `slice` : `meet`}`
      }
    });
    return () => animationRef.current.destroy();
  }, []);


  useEffect(() => {
    if(animationRef.current && autoplay){
      if(visible){
        animationRef.current.play()
      }
      else{
        animationRef.current.pause()
      }
    }
  },[visible])
  
  return (
    <Container className={className} ref={containerRef} />
  );
})