import { pixelate, round } from "./helpers";

export enum ScreenSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  XLARGE = "XLARGE",
  MAX = "MAX",
}

export enum Layout {
  GRID = "GRID",
  BLOCK = "BLOCK",
}

export enum VerticalSpacingVariant {
  NONE = "NONE",
  LARGE = "LARGE",
  SMALL = "SMALL"
}

export enum VerticalSpacingType {
  MARGIN = "margin",
  PADDING = "padding"
}

export const sizesOrdered = [
  ScreenSize.SMALL,
  ScreenSize.MEDIUM,
  ScreenSize.LARGE,
  ScreenSize.XLARGE,
  ScreenSize.MAX
]

export const unit:number = 8;
export const spacer:number = unit * 4;
export const gap:number = unit * 7;

export const breakpoint: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 0,
  [ScreenSize.MEDIUM]: 768,
  [ScreenSize.LARGE]: 1024,
  [ScreenSize.XLARGE]: 1400,
  [ScreenSize.MAX]: 2000,
};

export const margin: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 24,
  [ScreenSize.MEDIUM]: 24,
  [ScreenSize.LARGE]: 24,
  [ScreenSize.XLARGE]: 80,
  [ScreenSize.MAX]: 80,
};

export const gutter: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 24,
  [ScreenSize.MEDIUM]: 24,
  [ScreenSize.LARGE]: 24,
  [ScreenSize.XLARGE]: 40,
  [ScreenSize.MAX]: 40,
};

export const columns: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 6,
  [ScreenSize.MEDIUM]: 8,
  [ScreenSize.LARGE]: 12,
  [ScreenSize.XLARGE]: 12,
  [ScreenSize.MAX]: 12,
};

export const header: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 50,
  [ScreenSize.MEDIUM]: 64,
  [ScreenSize.LARGE]: 80,
  [ScreenSize.XLARGE]: 80,
  [ScreenSize.MAX]: 80,
};

export const radius: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 24,
  [ScreenSize.MEDIUM]: 32,
  [ScreenSize.LARGE]: 32,
  [ScreenSize.XLARGE]: 40,
  [ScreenSize.MAX]: 40,
};

export const verticalSpacingLarge: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 64,
  [ScreenSize.MEDIUM]: 80,
  [ScreenSize.LARGE]: 120,
  [ScreenSize.XLARGE]: 160,
  [ScreenSize.MAX]: 160,
};

export const verticalSpacingSmall: {[key in ScreenSize]:number} = {
  [ScreenSize.SMALL]: 32,
  [ScreenSize.MEDIUM]: 40,
  [ScreenSize.LARGE]: 48,
  [ScreenSize.XLARGE]: 64,
  [ScreenSize.MAX]: 64,
};

export const verticalSpacing: {[key in VerticalSpacingVariant]} = {
  [VerticalSpacingVariant.SMALL]: verticalSpacingSmall,
  [VerticalSpacingVariant.LARGE]: verticalSpacingLarge,
};

export const getSizesOrdered = () => sizesOrdered.slice();

export const getColumns = (size:ScreenSize):number => columns[size];

export const getMediaQuery = (size:ScreenSize):string => `@media screen and (min-width: ${breakpoint[size]}px)`

export const getImageWidthAsStyle = (size:ScreenSize, {span,total=12,margins=0,gutters=0}):string => {
	return `calc((((${size === ScreenSize.MAX ? getMaxWidthAsPixels() : `100vw`} - ${getMarginAsPixels(size,{multiplier:2})}) * ${round(1 / total)}) * ${span ? span : total}) - ${getGutterAsPixels(size)} ${margins > 0 && gutters > 0 ? ` + ${pixelate(getMarginAsNumber(size,{multiplier:margins}) + getGutterAsNumber(size,{multiplier:gutters}))}` : ''})`;
}


//NUMBER
export const getBreakpointAsNumber = (size:ScreenSize):number => breakpoint[size];

export const getMarginAsNumber = (size:ScreenSize,{multiplier=1}:{multiplier?:number}={multiplier:1}):number => margin[size] * multiplier;

export const getGutterAsNumber = (size:ScreenSize,{multiplier=1}:{multiplier?:number}={multiplier:1}):number => gutter[size] * multiplier;

export const getHeaderAsNumber = (size:ScreenSize):number => header[size];

export const getRadiusAsNumber = (size:ScreenSize):number => radius[size];

export const getMaxWidthAsNumber = ():number => breakpoint[ScreenSize.MAX];

export const getSpacerAsNumber = ({multiplier=1}:{multiplier?:number}={multiplier:1}):number => spacer * multiplier;

export const getGapAsNumber = ({multiplier=1}:{multiplier?:number}={multiplier:1}):number => gap * multiplier;

export const getUnitAsNumber = ({multiplier=1}:{multiplier?:number}={multiplier:1}):number => unit * multiplier;

export const getVerticalPaddingAsNumber = (size:ScreenSize,{multiplier=1}:{multiplier?:number}={multiplier:1}):number => verticalPadding[size] * multiplier;

export const getVerticalSpacingAsNumber = (size:ScreenSize,variant:VerticalSpacingVariant,{multiplier=1}:{multiplier?:number}={multiplier:1}):number => verticalSpacing[variant][size] * multiplier;


//PIXELATED
export const getBreakpointAsPixels = (size:ScreenSize):string => pixelate(getBreakpointAsNumber(size));

export const getMarginAsPixels = (size:ScreenSize,{multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getMarginAsNumber(size,{multiplier}));

export const getGutterAsPixels = (size:ScreenSize,{multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getGutterAsNumber(size,{multiplier}));

export const getHeaderAsPixels = (size:ScreenSize):string => pixelate(header[size]);

export const getRadiusAsPixels = (size:ScreenSize):string => pixelate(radius[size]);

export const getMaxWidthAsPixels = ():string => pixelate(getMaxWidthAsNumber());

export const getSpacerAsPixels = ({multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getSpacerAsNumber({multiplier}));

export const getGapAsPixels = ({multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getGapAsNumber({multiplier}));

export const getUnitAsPixels = ({multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getUnitAsNumber({multiplier}));

export const getVerticalPaddingAsPixels = (size:ScreenSize,{multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getVerticalPaddingAsNumber(size,{multiplier}));

export const getVerticalSpacingAsPixels = (variant:VerticalSpacingVariant,size:ScreenSize,{multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getVerticalSpacingAsNumber(size,variant,{multiplier}));

export const getRadiusAsStyle = ():string => `
  border-radius: ${getRadiusAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    border-radius: ${getRadiusAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    border-radius: ${getRadiusAsPixels(ScreenSize.LARGE)};
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    border-radius: ${getRadiusAsPixels(ScreenSize.XLARGE)};
  }

  ${getMediaQuery(ScreenSize.MAX)}{
    border-radius: ${getRadiusAsPixels(ScreenSize.MAX)};
  }
`

export const getVerticalSpacingAsStyle = (type:VerticalSpacingType,{top,bottom}:{top?:VerticalSpacingVariant,bottom?:VerticalSpacingVariant}):string => {
  let style = ``;

  if(top && top !== VerticalSpacingVariant.NONE){
    style += `
      ${type}-top: ${getVerticalSpacingAsPixels(top,ScreenSize.SMALL)};

      ${getMediaQuery(ScreenSize.MEDIUM)}{
        ${type}-top: ${getVerticalSpacingAsPixels(top,ScreenSize.MEDIUM)};
      }

      ${getMediaQuery(ScreenSize.LARGE)}{
        ${type}-top: ${getVerticalSpacingAsPixels(top,ScreenSize.LARGE)};
      }

      ${getMediaQuery(ScreenSize.XLARGE)}{
        ${type}-top: ${getVerticalSpacingAsPixels(top,ScreenSize.XLARGE)};
      }

      ${getMediaQuery(ScreenSize.MAX)}{
        ${type}-top: ${getVerticalSpacingAsPixels(top,ScreenSize.MAX)};
      }
    `
  }

  if(bottom && bottom !== VerticalSpacingVariant.NONE){
    style += `
    ${type}-bottom: ${getVerticalSpacingAsPixels(bottom,ScreenSize.SMALL)};

      ${getMediaQuery(ScreenSize.MEDIUM)}{
        ${type}-bottom: ${getVerticalSpacingAsPixels(bottom,ScreenSize.MEDIUM)};
      }

      ${getMediaQuery(ScreenSize.LARGE)}{
        ${type}-bottom: ${getVerticalSpacingAsPixels(bottom,ScreenSize.LARGE)};
      }

      ${getMediaQuery(ScreenSize.XLARGE)}{
        ${type}-bottom: ${getVerticalSpacingAsPixels(bottom,ScreenSize.XLARGE)};
      }

      ${getMediaQuery(ScreenSize.MAX)}{
        ${type}-bottom: ${getVerticalSpacingAsPixels(bottom,ScreenSize.MAX)};
      }
    `
  }
  
  return style;
}

export const getColumnWidthAsNumber = (size:ScreenSize,span:number,windowWidth?:number):number => {
  const contentWidth = Math.min(windowWidth || getMaxWidthAsNumber(),getMaxWidthAsNumber());
  const gutter = getGutterAsNumber(size);
  const margin = getMarginAsNumber(size);
  const columns = getColumns(size);
  return (((contentWidth + gutter - (margin * 2)) / columns) * span) - gutter;
}

export const getColumnsWidthAsStyle = (size:ScreenSize,span:number,length:number):string => {
  if(size === ScreenSize.MAX){
    return pixelate(getColumnWidthAsNumber(size,span * length));
  }
  return `calc((((100vw + ${getGutterAsPixels(size)} - ${getMarginAsPixels(size,{multiplier:2})}) / ${getColumns(size)}) * ${span * length}) - ${getGutterAsPixels(size)})`;
}

export const getIndentWidthAsStyle = (size:ScreenSize,span:number):string => {
  return span ? `calc((((100vw + ${getGutterAsPixels(size)} - ${getMarginAsPixels(size,{multiplier:2})}) / ${getColumns(size)}) * ${span}) - ${getGutterAsPixels(size)})` : `0`;
}

export const getImageSizes = (sizes:any = {},{defaultSize="100vw"}:{defaultSize?:string} = {defaultSize:"100vw"}) => {
	let props:object|null = null;
	let result:string = defaultSize;
	let imageSize:string;

	sizesOrdered.forEach(size => {
		props = sizes[size] ? {...props,...sizes[size]} : props;
		if(props){
			imageSize = getImageWidthAsStyle(size,props);
			result = `(min-width: ${getBreakpointAsPixels(size)}) ${imageSize}, ${result}`;
		}
	});

	return result;
}

export const getGridAtSize = (size:ScreenSize,columns?:number):string => `
  grid-template-columns: repeat(${columns || getColumns(size)}, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(size)};
`

export const GRID = `
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: repeat(${getColumns(ScreenSize.SMALL)}, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-template-columns: repeat(${getColumns(ScreenSize.MEDIUM)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-template-columns: repeat(${getColumns(ScreenSize.LARGE)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(ScreenSize.LARGE)};
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    grid-template-columns: repeat(${getColumns(ScreenSize.XLARGE)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(ScreenSize.XLARGE)};
  }

  ${getMediaQuery(ScreenSize.MAX)}{
    grid-template-columns: repeat(${getColumns(ScreenSize.MAX)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(ScreenSize.MAX)};
  }
`

export const GRID_LAYOUT = `
  ${GRID}
  
`

export const CONTAINER = `
  width: 100%;
  display: flex;
  justify-content: center;

  padding-left: ${getMarginAsPixels(ScreenSize.SMALL)};
  padding-right: ${getMarginAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    padding-left: ${getMarginAsPixels(ScreenSize.MEDIUM)};
    padding-right: ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    padding-left: ${getMarginAsPixels(ScreenSize.LARGE)};
    padding-right: ${getMarginAsPixels(ScreenSize.LARGE)};
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    padding-left: ${getMarginAsPixels(ScreenSize.XLARGE)};
    padding-right: ${getMarginAsPixels(ScreenSize.XLARGE)};
  }

  ${getMediaQuery(ScreenSize.MAX)}{
    padding-left: ${getMarginAsPixels(ScreenSize.MAX)};
    padding-right: ${getMarginAsPixels(ScreenSize.MAX)};
  }

  >*{
    flex: 1 0 auto;
    max-width: ${pixelate(getMaxWidthAsNumber() - getMarginAsNumber(ScreenSize.MAX,{multiplier:2}))};
  }
`;

export const BLOCK_LAYOUT = `
  display: block;
  width: 100%;
`

const layouts: {[key in Layout]:string} = {
  [Layout.GRID]: GRID_LAYOUT,
  [Layout.BLOCK]: BLOCK_LAYOUT,
};

export const getLayout = (layout:Layout):any => layouts[layout]

export const getLayoutByName = (name:string):any => {
  if(name){
      switch(name.toUpperCase()){
          case 'GRID': return layouts[Layout.GRID];
          case 'BLOCK': return layouts[Layout.BLOCK];
      }
  }
  
  return layouts[Layout.BLOCK];
}