import styled, { css } from 'styled-components';
import { Color, getColor } from './color';
import { Font, getFont } from './font';
import { pixelate } from './helpers';
import { breakpoint, getMediaQuery, getUnitAsPixels, ScreenSize } from './layout';

export enum Type {
  HEADING_1 = 'HEADING_1',
  HEADING_2 = 'HEADING_2',
  HEADING_3 = 'HEADING_3',
  HEADING_4 = 'HEADING_4',
  HEADING_5 = 'HEADING_5',
  HEADING_6 = 'HEADING_6',
  BODY_1 = 'BODY_1',
  BODY_2 = 'BODY_2',
  BODY_3 = 'BODY_3',
  BODY_4 = 'BODY_4',
  LABEL_1 = 'LABEL_1',
  LABEL_2 = 'LABEL_2',
  LABEL_3 = 'LABEL_3',
  NAVIGATION = 'NAVIGATION',
  FIELD_LABEL = 'FIELD_LABEL',
  FIELD_INPUT = 'FIELD_INPUT',
  FORM_HEADING = 'FORM_HEADING',
  FORM_SUBHEADING = 'FORM_SUBHEADING',
  FORM_BODY = 'FORM_BODY',
  FORM_BUTTON = 'FORM_BUTTON',
}

const typography: { [key in Type]: any } = {
  HEADING_1: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    text-decoration: none;
    font-weight: 800;
    font-size: 44px;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 66px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 90px;
    }
  `,
  HEADING_2: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 34px;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 56px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 78px;
    }
  `,
  HEADING_3: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 28px;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 38px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 48px;
    }
  `,
  HEADING_4: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 22px;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 30px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 38px;
    }
  `,
  HEADING_5: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 18px;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 24px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 30px;
    }
  `,
  HEADING_6: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 18px;
    }
  `,
  BODY_1: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    font-size: 24px;
    letter-spacing: -0.04em;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 36px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 46px;
    }
  `,
  BODY_2: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    line-height: 1.5em;
    font-size: 18px;
    letter-spacing: -0.04em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 24px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 30px;
    }
  `,
  BODY_3: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    line-height: 1.5em;
    font-size: 14px;
    letter-spacing: -0.04em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 18px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 22px;
    }
  `,
  BODY_4: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    font-size: 11px;
    letter-spacing: -0.04em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 16px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 18px;
    }
  `,
  LABEL_1: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  `,
  LABEL_2: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    font-size: 12px;
    text-transform: uppercase;
  `,
  LABEL_3: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
  `,
  NAVIGATION: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    text-decoration: none;
    font-weight: 800;
    font-size: 44px;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      font-size: 66px;
    }

    ${getMediaQuery(ScreenSize.LARGE)}{
      font-size: 22px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      font-size: 30px;
    }
  `,
  FORM_HEADING: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 30px;
    line-height: 1.3em;
  `,
  FORM_SUBHEADING: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 18px;
    line-height: 1.3em;
  `,
  FORM_BUTTON: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 800;
    text-decoration: none;
    font-size: 18px;
  `,
  FORM_BODY: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.3em;
  `,
  FIELD_LABEL: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    font-size: 12px;
  `,
  FIELD_INPUT: css`
    font-family: ${getFont(Font.OBJECT_SANS)};
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
  `,
};

export const getTypography = (type: Type): any => typography[type];

export const getTypographyComponent = (type: Type): any =>
  styled.div`
    ${getTypography(type)}
  `;

export const BodyText = styled.div`
  color: ${getColor(Color.DARK_BLUE)};
  ${getTypography(Type.BODY_3)}

  strong{
    font-weight: 800;
  }

  em{
    background-color: ${({theme}) => theme.background.secondary};
  }

  a {
    color: ${getColor(Color.DARK_BLUE)};
    text-decoration-color: ${getColor(Color.YELLOW)};
    text-underline-offset: 0.12em;
    text-decoration-skip-ink: none;
  }

  >p, >ol, >ul {
    margin-bottom: ${getUnitAsPixels({multiplier:1})};
    ${getMediaQuery(ScreenSize.LARGE)}{
      margin-bottom: ${getUnitAsPixels({multiplier:2})};
    }

    &:last-child{
      margin-bottom: 0;
    }

    
  }

  >ol {
    padding-inline-start: ${getUnitAsPixels({multiplier:4})};
    list-style: auto;

    >li {
      padding-inline-start: ${getUnitAsPixels()};
      
      &::marker{
        color: ${({theme}) => theme.foreground.tertiary};
      }
    }
  }

  >ul {
    padding-inline-start: ${getUnitAsPixels({multiplier:4})};
    list-style: disc;

    >li {
      padding-inline-start: ${getUnitAsPixels()};

      &::marker{
        color: ${({theme}) => theme.foreground.tertiary};
      }
    }
  }

  >h2, >h3, >h4, >h4, >h5, >h6 {
    margin-top: ${getUnitAsPixels({multiplier:1})};
    margin-bottom: ${getUnitAsPixels({multiplier:1})};
    
    ${getMediaQuery(ScreenSize.LARGE)}{
      margin-top: ${getUnitAsPixels({multiplier:2})};
      margin-bottom: ${getUnitAsPixels({multiplier:2})};
    }

    &:first-child{
      margin-top: 0;
    }
  }

  >h2 {
    ${getTypography(Type.HEADING_3)}
  }

  >h3 {
    ${getTypography(Type.HEADING_4)}
  }

  >h4 {
    ${getTypography(Type.HEADING_5)}
  }

  >h5 {
    ${getTypography(Type.HEADING_6)}
  }

  >h4, >h4, >h5, >h6 {
    ${getTypography(Type.BODY_3)}
  }
`