import {  get, map } from "lodash"
import { getAppRegisterPath, getInternalURLFromPage } from "./link"
import { TextLink } from "./types"
import { DatoCmsLinkExternal, DatoCmsLinkInternal } from "./types/graphql"

export const parseLinks = (links:DatoCmsLinkExternal|DatoCmsLinkInternal[],{rootLocale}:{rootLocale?:string}={}):TextLink[] => (
  map(links, (link) => parseLink(link,{rootLocale}))
)

export const parseLink = (link: DatoCmsLinkExternal|DatoCmsLinkInternal,{rootLocale}:{rootLocale?:string}={}):TextLink => {
  const type = get(link,'model.apiKey');

  if(type === 'link_internal' || type === 'button_internal'){
    return parseLinkInternal(link as DatoCmsLinkInternal,{rootLocale});
  }
  else if(type === 'link_external' || type === 'button_external'){
    return parseLinkExternal(link as DatoCmsLinkExternal);
  }
  else if(type === 'link_register' || type === 'button_register'){
    return {
      isInternal:true,
      label:link.label,
      url: getAppRegisterPath()
    }
  }

  return {
    isInternal:false,
    label:link.label,
    url:''
  };
}

export const parseLinkInternal = (link:DatoCmsLinkInternal,{rootLocale}:{rootLocale?:string}={}):TextLink => ({
  isInternal: get(link,'page.model.apiKey') === "app_register" || get(link,'page.model.apiKey') === "app_book" ? false : true,
  label: link.label,
  url: getInternalURLFromPage(link.page,{rootLocale})
})

export const parseLinkExternal = (link:DatoCmsLinkExternal):TextLink => ({
  isInternal: false,
  label: link.label,
  url: link.url
})