import { globalHistory } from '@reach/router';
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { parseLink, parseLinks } from "../../../common/content";
import { getPageHomePath } from "../../../common/link";
import { useLocale, useRootLocale } from "../../../context/locale";
import { Color } from "../../../theme/color";
import { ButtonType } from "../../common/button";
import { Birds, Buttons, CTAButton, Link, LinkItem, LinkList, Logo, LogoContainer, Menu, MenuButton } from "./header.styles";
import { HeaderProps } from "./header.types";

const isClient = typeof window !== "undefined";

export const Header = ({ primaryColor, secondaryColor, buttonColor }: HeaderProps) => {
  const locale = useLocale();
  const rootLocale = useRootLocale()

  const data = useStaticQuery(graphql`
    query Header {
      header:datoCmsHeader {
        links {
          ...LinkInternal
          ...LinkExternal
        }
        buttons {
          ...ButtonInternal
          ...ButtonExternal
        }
        appLabel
        appQr{
          gatsbyImageData(
            width: 80
            placeholder: BLURRED
            forceBlurhash: false
          )
        }
        appUrl
      }
    }
  `);

  const header = get(data, 'header', []);
  const links = parseLinks(get(header, 'links'), { rootLocale });
  const buttons = get(header, 'buttons');

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setMenuOpen(false)
    })
  }, [setMenuOpen])

  useEffect(() => {
    if (isClient) {
      const beacon = document.getElementById('beacon-container');
      if (beacon) {
        if (menuOpen) {
          beacon.style.visibility = "hidden";
        }
        else {
          beacon.style.visibility = "visible";
        }
      }
    }
  }, [menuOpen]);

  return (
    <>
      <LogoContainer to={getPageHomePath({ locale, rootLocale, addTrailingSlash: true })}>
        <Logo viewBox="0 0 44 40" xmlns="http://www.w3.org/2000/svg" $primaryColor={primaryColor}>
          <path d="M18.2196 40C10.6533 40 0 33.7344 0 19.8036C0 7.33929 7.78758 1.49638 14.4562 0.279033C22.2276 -1.1388 29.5583 2.94853 32.2819 10.2219L28.5437 11.6325C26.1583 5.26402 20.1085 3.3224 15.1704 4.22368C12.141 4.77636 9.39763 6.40551 7.44938 8.8095C5.18812 11.5982 3.99363 15.3984 3.99363 19.8036C3.99363 32.0349 13.5783 35.9903 18.2196 35.9903C21.9974 35.9903 24.917 35.0674 27.0613 33.7633C23.4167 31.2528 22.9238 27.03 24.3522 24.0842C25.7427 21.2178 28.6462 19.9914 31.5767 21.0318C34.6349 22.1154 36.2305 25.0432 35.6423 28.4894C35.4012 29.8964 34.8219 31.2979 33.9585 32.6092C38.2633 33.711 40.4022 31.493 40.5156 31.3684L40.4958 31.3901L43.5 34.0307C43.3453 34.2077 40.8682 36.9566 36.2647 36.9566C34.7374 36.9566 32.978 36.655 30.992 35.8567C30.7869 36.0247 30.5765 36.1872 30.3588 36.3461C28.0957 38.0114 24.1489 40 18.2196 40ZM29.5259 24.6748C28.7038 24.6748 28.2199 25.2708 27.9446 25.8379C26.9282 27.9331 28.3044 29.8097 29.4773 30.5592C29.722 30.7164 29.963 30.8627 30.1987 30.9999C31.0514 29.8928 31.5443 28.7657 31.708 27.8085C31.8897 26.741 31.7853 25.3539 30.2491 24.8102C29.981 24.7163 29.7418 24.6748 29.5259 24.6748Z" />
        </Logo>
      </LogoContainer>
      <Menu $isOpen={menuOpen}>
        <Birds viewBox="0 0 147 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M46.7395 29.3917C43.0626 25.7084 38.5371 23.7959 33.3046 23.7959C28.072 23.7959 23.5466 25.6375 19.8696 29.3917C17.1119 32.1541 15.4148 35.3416 14.7077 38.8832C14.5663 38.8832 14.4249 38.8832 14.2835 38.8832C10.3237 38.8832 7.00032 40.2999 4.17191 43.0623C1.69704 45.5415 0.282841 48.4456 0 51.7747H76.7914C76.0136 48.4456 74.3166 45.5415 71.771 42.9915C68.0941 39.2374 63.5686 37.3957 58.336 37.3957C56.0026 37.3957 53.8106 37.7499 51.8307 38.4582C51.0529 35.1291 49.3558 32.0125 46.7395 29.3917Z" fill="white" />
          <path d="M146.795 41.0117L129.117 47.8825L146.795 55.8157V41.0117Z" fill="#019999" />
          <path d="M99.5603 52.0621L105.571 51.7079L103.944 47.5996L99.5603 52.0621Z" fill="#019999" />
          <path d="M133.36 81.528L123.602 57.1617L131.097 51.6367H118.652L110.733 57.6575C107.551 59.995 107.551 62.2616 110.733 64.5991L133.36 81.528Z" fill="#019999" />
          <path d="M112.925 19.1249C109.036 21.2499 108.116 24.3665 110.167 28.3331L116.814 44.6955L129.683 43.4205L123.743 27.0582L140.431 2.9751L112.925 19.1249Z" fill="#019999" />
          <path d="M103.591 47.8119C103.591 49.2994 104.086 50.5035 105.076 51.4952C106.066 52.4868 107.338 53.0535 108.753 53.0535H131.168L140.643 48.3077L131.168 42.6411H108.753C107.338 42.6411 106.066 43.1369 105.076 44.1286C104.086 45.1911 103.591 46.3952 103.591 47.8119Z" fill="white" />
          <path d="M109.035 47.67C109.318 47.3866 109.46 47.1033 109.46 46.7491C109.46 46.395 109.318 46.0408 109.035 45.8283C108.753 45.545 108.47 45.4033 108.116 45.4033C107.763 45.4033 107.409 45.545 107.197 45.8283C106.914 46.1116 106.773 46.395 106.773 46.7491C106.773 47.1033 106.914 47.4575 107.197 47.67C107.48 47.9533 107.763 48.095 108.116 48.095C108.47 48.095 108.823 47.9533 109.035 47.67Z" fill="#019999" />
          <path d="M62.0837 20.7538L66.185 20.5413L65.0536 17.708L62.0837 20.7538Z" fill="#019999" />
          <path d="M74.0339 6.30409C72.9732 2.90413 70.0741 0.920823 65.4072 0.354163L52.8208 0L64.0637 5.38327L69.1549 16.4331L78.5594 17.2831L74.0339 6.30409Z" fill="#019999" />
          <path d="M84.2869 0.354131C79.62 0.849959 76.7209 2.83327 75.6602 6.30406L71.1348 17.3539L80.5392 16.5039L85.6304 5.45407L96.8733 0.0708008L84.2869 0.354131Z" fill="white" />
          <path d="M68.3063 21.3913H83.509L92.8427 14.4497L68.3063 14.3789C67.3163 14.3789 66.5385 14.7331 65.8314 15.3706C65.1243 16.0789 64.7708 16.9289 64.7708 17.8497C64.7708 18.8414 65.1243 19.6913 65.8314 20.3288C66.5385 21.108 67.3163 21.3913 68.3063 21.3913Z" fill="white" />
          <path d="M68.4477 17.779C68.6598 17.5665 68.7306 17.354 68.7306 17.1415C68.7306 16.8582 68.6598 16.6457 68.4477 16.4332C68.3063 16.2915 68.0942 16.2207 67.8113 16.2207C67.5992 16.2207 67.3871 16.2915 67.1749 16.4332C66.9628 16.6457 66.8921 16.8582 66.8921 17.1415C66.8921 17.354 66.9628 17.5665 67.1749 17.779C67.3871 17.9915 67.5992 18.0623 67.8113 18.0623C68.0942 18.0623 68.3063 17.9915 68.4477 17.779Z" fill="#019999" />
          <path d="M95.8834 16.5039V12.2539L83.9333 14.2372L95.8834 16.5039Z" fill="#019999" />
        </Birds>
        <LinkList $primaryColor={primaryColor} $secondaryColor={secondaryColor}>
          {links.map((link, index) => <LinkItem key={index}>{link.isInternal ? <Link as={GatsbyLink} to={link.url} activeClassName="active">{link.label}</Link> : <Link href={link.url} target="_blank">{link.label}</Link>}</LinkItem>)}
        </LinkList>
        <Buttons>
          {buttons && buttons.map(({ icon, ...link }, index) => {
            const parsedLink = parseLink(link, { rootLocale });
            return <CTAButton key={index} icon={icon} {...parsedLink} type={buttonColor === Color.DARK_BLUE ? ButtonType.TERTIARY : ButtonType.PRIMARY} />
          })}
        </Buttons>
      </Menu>

      <MenuButton onClick={() => setMenuOpen(!menuOpen)} open={menuOpen}>
        <span></span>
      </MenuButton>
    </>
  );

}

