import ColorTransform from 'color';
export {default as ColorTransform} from 'color';

export enum Color {
  PINK = 'PINK',
  DARK_BLUE = 'DARK_BLUE',
  DARK_GREEN = 'DARK_GREEN',
  YELLOW = 'YELLOW',
  PURPLE = 'PURPLE',
  MID_BLUE = 'MID_BLUE',
  WHITE = 'WHITE',
  LIGHT_PURPLE = 'LIGHT_PURPLE',
  LIGHT_GREEN = 'LIGHT_GREEN',
  WASH_GREEN = 'WASH_GREEN',
  WASH_PINK = 'WASH_PINK',
  WASH_PURPLE = 'WASH_PURPLE',
  WASH_GREY = 'WASH_GREY',
  WASH_BLUE = 'WASH_BLUE',
  WASH_YELLOW = 'WASH_YELLOW',
  GRASS_GREEN = 'GRASS_GREEN',
  LIGHT_GREY = 'LIGHT_GREY',
  DARK_GREY = 'DARK_GREY',
  MID_GREY = 'MID_GREY',
  BLACK = 'BLACK',
  LIGHTEST_GREY = 'LIGHTEST_GREY',
}

export enum Theme {
  WHITE = 'White',
  YELLOW = 'Yellow',
  GREEN = 'Green',
  PINK = 'Pink',
  GREY = 'Grey',
  LIGHT_GREY = 'Light Grey',
}

export type ThemeType = {
  background: {
    primary: string;
    secondary: string;
  };
  foreground: {
    primary: string;
    secondary: string;
    alternative: string;
  };
};

const colors: { [key in Color]: string } = {
  [Color.PINK]: '#FF3399',
  [Color.DARK_BLUE]: '#262642',
  [Color.DARK_GREEN]: '#019999',
  [Color.YELLOW]: '#F5DF4D',
  [Color.PURPLE]: '#990066',
  [Color.MID_BLUE]: '#4A8DFF',
  [Color.WHITE]: '#FFFFFF',
  [Color.LIGHT_PURPLE]: '#8E8EAD',
  [Color.LIGHT_GREEN]: '#00CCBE',
  [Color.WASH_GREEN]: '#E5FAF9',
  [Color.WASH_PINK]: '#FFF2FB',
  [Color.WASH_PURPLE]: '#FDEEF8',
  [Color.WASH_GREY]: '#F3F4F7',
  [Color.WASH_BLUE]: '#F2FAFD',
  [Color.WASH_YELLOW]: '#FEFCED',
  [Color.GRASS_GREEN]: '#94D483',
  [Color.LIGHT_GREY]: '#E6E9EE',
  [Color.DARK_GREY]: '#7E7E99',
  [Color.MID_GREY]: '#B4B4BF',
  [Color.BLACK]: '#000000',
  [Color.LIGHTEST_GREY]: '#F3F4F7'
};

export const getColor = (color: Color): string => colors[color];

const themes: { [key in Theme]: ThemeType } = {
  [Theme.WHITE]: {
    background: {
      primary: getColor(Color.WHITE),
      secondary: ColorTransform(getColor(Color.DARK_BLUE)).alpha(0.05).rgb().string(),
    },
    foreground: {
      primary: getColor(Color.DARK_BLUE),
      secondary: getColor(Color.LIGHT_PURPLE),
      alternative: getColor(Color.PINK),
    },
  },
  [Theme.YELLOW]: {
    background: {
      primary: getColor(Color.WASH_YELLOW),
      secondary: ColorTransform(getColor(Color.DARK_BLUE)).alpha(0.10).rgb().string(),
    },
    foreground: {
      primary: getColor(Color.DARK_BLUE),
      secondary: getColor(Color.LIGHT_PURPLE),
      alternative: getColor(Color.PINK),
    },
  },
  [Theme.GREEN]: {
    background: {
      primary: getColor(Color.WASH_GREEN),
      secondary: ColorTransform(getColor(Color.DARK_BLUE)).alpha(0.10).rgb().string(),
    },
    foreground: {
      primary: getColor(Color.DARK_GREEN),
      secondary: getColor(Color.LIGHT_PURPLE),
      alternative: getColor(Color.PINK),
    },
  },
  [Theme.PINK]: {
    background: {
      primary: getColor(Color.WASH_PINK),
      secondary: ColorTransform(getColor(Color.DARK_BLUE)).alpha(0.10).rgb().string(),
    },
    foreground: {
      primary: getColor(Color.PINK),
      secondary: getColor(Color.LIGHT_PURPLE),
      alternative: getColor(Color.PINK),
    },
  },
  [Theme.GREY]: {
    background: {
      primary: getColor(Color.WASH_GREY),
      secondary: ColorTransform(getColor(Color.DARK_BLUE)).alpha(0.10).rgb().string(),
    },
    foreground: {
      primary: getColor(Color.PINK),
      secondary: getColor(Color.LIGHT_PURPLE),
      alternative: getColor(Color.PINK),
    },
  },
  [Theme.LIGHT_GREY]: {
    background: {
      primary: getColor(Color.LIGHTEST_GREY),
      secondary: ColorTransform(getColor(Color.DARK_BLUE)).alpha(0.10).rgb().string(),
    },
    foreground: {
      primary: getColor(Color.PINK),
      secondary: getColor(Color.LIGHT_PURPLE),
      alternative: getColor(Color.PINK),
    },
  },
  
};

export const getTheme = (theme: Theme): ThemeType => themes[theme] || themes[Theme.WHITE];

