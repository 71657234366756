import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { createContext, ReactNode, useContext } from 'react';

interface ConfigurationProviderProps {
  children: ReactNode;
  configuration: Configuration;
}

interface Configuration {
  pricing: PricingConfiguration;
  vehicle: VehicleConfiguration;
}

export interface PricingConfiguration {
  items: PricingConfigurationItem[];
}

interface PricingConfigurationItem {
  media: {
    gatsbyImageData:IGatsbyImageData;
  };
  heading: string;
  payAsYouGo: string;
  subscription: string;
}

export interface VehicleConfiguration {
  sequence: {
    gatsbyImageData:IGatsbyImageData
  }[];
  items: VehicleConfigurationItem[];
}

export interface VehicleConfigurationItem {
  heading: string;
  subheading: string;
}

const Context = createContext({
  pricing:{
    items:[]
  },
  vehicle:{
    items:[],
    sequence:[]
  }
});

const ConfigurationProvider = ({ children, configuration }:ConfigurationProviderProps):ReactNode => {
  return (
		<Context.Provider value={configuration}>
			{children}
		</Context.Provider>
	);
};

const useVehicleConfiguration = ():VehicleConfiguration => {
	const context = useContext(Context);
	if (context === undefined) {
		throw new Error('useVehicleConfiguration must be used within a ConfigurationProvider');
	}
	return context.vehicle;
};

const usePricingConfiguration = ():PricingConfiguration => {
	const context = useContext(Context);
	if (context === undefined) {
		throw new Error('usePricingConfiguration must be used within a ConfigurationProvider');
	}
	return context.pricing;
};


export { ConfigurationProvider, useVehicleConfiguration, usePricingConfiguration };