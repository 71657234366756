import styled from "styled-components";
import { Color, ColorTransform, getColor } from "../../../theme/color";
import { getUnitAsPixels } from "../../../theme/layout";
import { Type, getTypography } from "../../../theme/typography";
import { ButtonType } from "./button.types";

export const Container = styled.button<{ $type: ButtonType }>`
  ${getTypography(Type.HEADING_6)}
  background-color: ${({ $type }) => $type === ButtonType.PRIMARY ? getColor(Color.PINK) : $type === ButtonType.TERTIARY ? getColor(Color.DARK_BLUE) : ColorTransform(getColor(Color.WHITE)).alpha(0.3).string()};
  color: ${({ $type }) => $type === ButtonType.PRIMARY || $type === ButtonType.TERTIARY ? getColor(Color.WHITE) : getColor(Color.DARK_BLUE)};
  height: ${getUnitAsPixels({ multiplier: 8 })};
  padding: 0 ${getUnitAsPixels({ multiplier: 3 })};
  border-radius: ${getUnitAsPixels({ multiplier: 4 })};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 100px;

  &:hover{
    background-color: ${({ $type }) => $type === ButtonType.PRIMARY ? getColor(Color.DARK_BLUE) : $type === ButtonType.TERTIARY ? getColor(Color.PINK) : getColor(Color.WHITE)};
  }
`

export const IconSVG = styled.svg`
  margin-inline-end: ${getUnitAsPixels({ multiplier: 1 })};
`