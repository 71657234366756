import styled from "styled-components";
import { Color, getColor } from "../../../theme/color";
import { ScreenSize, getMediaQuery, getUnitAsPixels } from "../../../theme/layout";

export const Container = styled.div<{ $isClockwise: boolean, $isActive: boolean }>`
  position: absolute;
  transition: opacity 1s, visibility 1s, transform 1s;
  opacity: ${({ $isActive }) => $isActive ? `1` : `0`};
  visibility: ${({ $isActive }) => $isActive ? `visible` : `hidden`};
  transform: ${({ $isActive, $isClockwise }) => $isActive ? `translate3d(0,0,0) rotate(${$isClockwise ? `15deg` : `-15deg`});` : `translate3d(0,100px,0) rotate(0deg);`}
`

export const Content = styled.div<{ $backgroundColor: Color }>`
  position: absolute;
  transform: translate(-50%,-50%);
  background-color: ${({ $backgroundColor }) => getColor($backgroundColor)};
  width: ${getUnitAsPixels({ multiplier: 5 })};
  height: ${getUnitAsPixels({ multiplier: 5 })};
  border-radius: ${getUnitAsPixels({ multiplier: 1 })};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);

  > img{
    width: ${getUnitAsPixels({ multiplier: 4 })};
  }

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    width: ${getUnitAsPixels({ multiplier: 10 })};
    height: ${getUnitAsPixels({ multiplier: 10 })};
    border-radius: ${getUnitAsPixels({ multiplier: 3 })};

    > img{
      width: ${getUnitAsPixels({ multiplier: 6 })};
    }
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    width: ${getUnitAsPixels({ multiplier: 16 })};
    height: ${getUnitAsPixels({ multiplier: 16 })};
    border-radius: ${getUnitAsPixels({ multiplier: 4 })};

    > img{
      width: ${getUnitAsPixels({ multiplier: 10 })};
    }
  }
`