export interface BadgeProps {
  icon?: BadgeIcon;
  className?: string;
}

export interface BadgeIconProps {
  icon: BadgeIcon;
}

export enum BadgeIcon {
  CHARGE = "Charge",
  LOCATION = "Location",
  LUNCH = "Lunch",
  MUSEUM = "Museum",
  RESTAURANT = "Restaurant",
  SPA = "Spa",
  WALKING = "Walking",
}