import React, { useRef } from "react";
import { Color } from "../../../theme/color";
import { Container, Content } from "./badge.styles";
import { BadgeIcon, BadgeIconProps, BadgeProps } from "./badge.types";

import { useVisibleOnScreen } from "../../../hooks/useVisibleOnScreen";
import ChargeSVG from "../../../images/icons/charge.svg";
import LocationSVG from "../../../images/icons/location.svg";
import LunchSVG from "../../../images/icons/lunch.svg";
import MuseumSVG from "../../../images/icons/museum.svg";
import RestaurantSVG from "../../../images/icons/restaurant.svg";
import SpaSVG from "../../../images/icons/spa.svg";
import WalkingSVG from "../../../images/icons/walking.svg";

const IconComponent = ({ icon }: BadgeIconProps) => {
  switch (icon) {
    case BadgeIcon.CHARGE: return <img src={ChargeSVG} alt="" />;
    case BadgeIcon.LOCATION: return <img src={LocationSVG} alt="" />;
    case BadgeIcon.LUNCH: return <img src={LunchSVG} alt="" />;
    case BadgeIcon.MUSEUM: return <img src={MuseumSVG} alt="" />;
    case BadgeIcon.RESTAURANT: return <img src={RestaurantSVG} alt="" />;
    case BadgeIcon.SPA: return <img src={SpaSVG} alt="" />;
    case BadgeIcon.WALKING: return <img src={WalkingSVG} alt="" />;
  }
}

const getIconColor = (icon: BadgeIcon | undefined): Color => {
  switch (icon) {
    case BadgeIcon.CHARGE: return Color.LIGHT_GREEN;
    case BadgeIcon.LOCATION: return Color.PINK;
    case BadgeIcon.LUNCH: return Color.YELLOW;
    case BadgeIcon.MUSEUM: return Color.GRASS_GREEN;
    case BadgeIcon.RESTAURANT: return Color.YELLOW;
    case BadgeIcon.SPA: return Color.PURPLE;
    case BadgeIcon.WALKING: return Color.MID_BLUE;
  }
  return Color.PURPLE;
}

const getAnimationProps = (icon: BadgeIcon | undefined): { $isClockwise: boolean } => {
  switch (icon) {
    case BadgeIcon.CHARGE: return { $isClockwise: true };
    case BadgeIcon.LOCATION: return { $isClockwise: true };
    case BadgeIcon.LUNCH: return { $isClockwise: false };
    case BadgeIcon.MUSEUM: return { $isClockwise: false };
    case BadgeIcon.RESTAURANT: return { $isClockwise: true };
    case BadgeIcon.SPA: return { $isClockwise: true };
    case BadgeIcon.WALKING: return { $isClockwise: false };
  }
  return { $isClockwise: false };
}

export const Badge = ({ className, icon }: BadgeProps) => {
  const containerRef = useRef();
  const { active } = useVisibleOnScreen(containerRef);

  return (
    <Container ref={containerRef} className={className} {...getAnimationProps(icon)} $isActive={active}>
      <Content $backgroundColor={getIconColor(icon)}>
        {icon && <IconComponent icon={icon} />}
      </Content>
    </Container>
  )
}