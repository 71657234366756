import styled from "styled-components";
import { Color, getColor } from "../../../theme/color";
import { getMediaQuery, getUnitAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant, GRID_LAYOUT, CONTAINER, BLOCK_LAYOUT, getSpacerAsPixels, GRID } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { Badge } from "../../common/badge";
import { LottieAnimation } from "../../common/lottieAnimation";

export const Container = styled.div`
  position: relative;
`

export const Curve = styled.div`
  position: absolute;
  width: 100%;
  height: 2vw;
  left: 0;
  bottom: 100%;
  overflow: hidden;

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150vw;
    height: 10vw;
    border-radius: 50% 50% 0 0;
  }
`

export const MainContainer = styled.div`
  background-color: ${getColor(Color.DARK_BLUE)};
  position: relative;

  ${Curve}{
    &:after{
      background-color: ${getColor(Color.DARK_BLUE)};
    }
  }
`

export const IllustrationContainer = styled.div`
  background-color: ${getColor(Color.WASH_YELLOW)};
  position: relative;
  width: 100%;
  overflow: hidden;

  ${Curve}{
    &:after{
      background-color: ${getColor(Color.WASH_YELLOW)};
    }
  }
`

export const Illustration = styled.svg`
  width: 100%;
`

export const Content = styled.div`
  overflow: hidden;
  width: 100%;
  ${CONTAINER}
`

export const Inner = styled.div`
  ${BLOCK_LAYOUT}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,{bottom:VerticalSpacingVariant.SMALL})}
`

export const LinksPrimary = styled.ul`
  grid-column: span 6;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 8;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 8;
  }
`

export const ItemPrimary = styled.li`
  ${getTypography(Type.HEADING_1)}
  margin-bottom: ${getUnitAsPixels({multiplier:1})};

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-bottom: ${getUnitAsPixels({multiplier:2})};
  }

  &:last-child{
    margin-bottom: 0;
  }
`

export const LinkPrimary = styled.a`
  text-decoration: none;
  color: ${getColor(Color.YELLOW)};
  transition: color 0.3s;

  &:hover{
    color: ${getColor(Color.WHITE)};
  }

  &.active{
    color: ${getColor(Color.WHITE)};
  }
`

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${getUnitAsPixels({multiplier:1})};
`

export const LinksSecondary = styled.ul`
  display: flex;
  justify-content: flex-end;
  gap: ${getUnitAsPixels({multiplier:1})};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    gap: ${getSpacerAsPixels({multiplier:1})};
  }
`

export const ItemSecondary = styled.li`
  ${getTypography(Type.HEADING_6)}
  text-align: right;
`

export const LinkSecondary = styled.a`
  text-decoration: none;
  color: ${getColor(Color.YELLOW)};
  transition: color 0.3s;

  &:hover{
    color: ${getColor(Color.WHITE)};
  }

  &.active{
    color: ${getColor(Color.WHITE)};
  }
`

export const LinksSocial= styled.ul`
  grid-column: span 6;
  display: flex;
  gap: ${getSpacerAsPixels({multiplier:1})};
`

export const ItemSocial = styled.li`
  
`

export const LinkSocial = styled.a`
  text-decoration: none;
`

export const IconSocial = styled.img`
  
`

export const Legal = styled.div`
  display: flex;
  gap: ${getUnitAsPixels({multiplier:1})};
  flex-direction: column;

  ${getMediaQuery(ScreenSize.LARGE)} {
    flex-direction: row;
    gap: ${getSpacerAsPixels({multiplier:1})};
    align-items: center;
  }
`

export const Smallprint = styled.div`
  ${getTypography(Type.BODY_4)}
  color: ${getColor(Color.YELLOW)};
  text-align: right;
`

export const BackLineContainer = styled.div`
  position: absolute;
  width: 100vw;
  bottom: -10.5vw;
`

export const FrontLineContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: calc(100% - 10.5vw);
  z-index: 2;
`

export const SpaBadge = styled(Badge)`
  position: absolute;
  bottom: 13vw;
  left: 16vw;
  z-index: 3;
`

export const WalkingBadge = styled(Badge)`
  position: absolute;
  bottom: 22vw;
  left: 50vw;
  z-index: 3;
`

export const RestaurantBadge = styled(Badge)`
  position: absolute;
  bottom: 14vw;
  right: 7vw;
  z-index: 3;
`

export const Top = styled.div`
  
  ${GRID}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,{top:VerticalSpacingVariant.LARGE,bottom:VerticalSpacingVariant.SMALL})}
`

export const Man = styled(LottieAnimation)`
  display: none;

  ${getMediaQuery(ScreenSize.LARGE)} {
    display: block;
    grid-column: span 4;
  }
`